import type { IconProps } from './types';

export const IconSuperMessage = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 500 500'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        fill={color}
        d='M434.4,23.2H65.6C29.5,23.2-0.1,52.8-0.1,89v232
	c0,36.2,29.5,65.8,65.6,65.8h41.3v90.8l89.6-90.8h238c36.1,0,65.6-29.6,65.6-65.8V89C500,52.8,470.5,23.2,434.4,23.2z M319.7,301
	L250,250.2L180.3,301l26.6-82.2l-69.7-50.8h86.1L250,85.9l26.6,82.2h86.1l-69.7,50.8L319.7,301z'
      />
    </svg>
  );
};
