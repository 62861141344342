import type { IconProps } from './types';

export const IconHorizontalAlignLeft = ({
  width,
  height,
  color = 'white',
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5610_230)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 61H452.381V84.8095H0V61ZM0 120.524H261.905V144.333H0V120.524ZM0 180.048H404.762V203.857H0V180.048ZM0 239.571H500V263.381H0V239.571ZM0 299.095H261.905V322.905H0V299.095ZM0 358.619H452.381V382.429H0V358.619ZM0 418.143H333.333V441.952H0V418.143Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_5610_230'>
          <rect width='500' height='380.952' fill='white' transform='translate(0 61)' />
        </clipPath>
      </defs>
    </svg>
  );
};
