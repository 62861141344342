import type { IconProps } from './types';

export const IconDots = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='57.6923' cy='249.692' r='57.6923' fill={color} />
      <circle cx='250' cy='249.692' r='57.6923' fill={color} />
      <circle cx='442.308' cy='249.692' r='57.6923' fill={color} />
    </svg>
  );
};
