import type { IconProps } from './types';

export const IconQuestionCircle = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 500 500'
      enableBackground='new 0 0 500 500'
    >
      <path
        fill={color}
        d='M250,500c-66.8,0-129.6-26-176.8-73.2S0,316.8,0,250S26,120.4,73.2,73.2S183.2,0,250,0s129.6,26,176.8,73.2
	S500,183.2,500,250s-26,129.6-73.2,176.8S316.8,500,250,500z M250,41.7C135.1,41.7,41.7,135.1,41.7,250S135.2,458.3,250,458.3
	c114.9,0,208.3-93.5,208.3-208.3C458.3,135.1,364.9,41.7,250,41.7z'
      />
      <path
        fill={color}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M225,290.4h40.7c-4.8-44.7,50.5-60,50.5-105.1c0-40.7-28-60.3-66.1-60.3
	c-27.6,0-50.2,13-66.6,31.8l25.7,23.5c10.1-10.5,21.4-17.8,35.2-17.8c16.4,0,27,10.1,27,26C271.3,219,217.4,239.3,225,290.4z
	 M245.4,375c16.9,0,29.1-13.3,29.1-30.5S262.1,314,245.4,314s-28.9,13.3-28.9,30.5S228.9,375,245.4,375z'
      />
    </svg>
  );
};
