import type { IconProps } from './types';

export const IconPaperPlane = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_3602_31)'>
        <path
          d='M13.3151 5.90071L7.00808 11.396C7.00808 11.396 3.13808 10.1536 2.03867 9.72306C0.939257 9.29247 0.605727 8.19483 1.70337 7.76424C2.80455 7.33541 15.5881 2.08188 16.6116 1.64953C17.8063 1.1713 18.1875 2.22306 17.854 3.13012C17.5204 4.03718 12.311 16.5313 11.7851 17.7736C11.2593 19.016 10.2569 18.3948 9.87396 17.4878C9.49102 16.5807 8.48867 13.6425 8.48867 13.6425'
          stroke={color}
          strokeWidth='1.05882'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3602_31'>
          <rect width='18' height='18' fill='white' transform='translate(0.5 1)' />
        </clipPath>
      </defs>
    </svg>
  );
};
