import type { IconProps } from './types';

export const IconArrow2Down = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_588_11090)'>
        <g clipPath='url(#clip1_588_11090)'>
          <path
            d='M500.437 143.437L463.562 106.562L250.437 319.687L37.3125 106.562L0.437498 143.437L250.437 393.437L500.437 143.437Z'
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_588_11090'>
          <rect width='500' height='500' fill={color} />
        </clipPath>
        <clipPath id='clip1_588_11090'>
          <rect
            width='286.875'
            height='500'
            fill={color}
            transform='translate(500.438 106.562) rotate(90)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
