import type { IconProps } from './types';

export const IconReload = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_600_33929)'>
        <path
          d='M33.0673 229.639C36.8251 179.215 57.9374 129.885 96.4726 91.3499C181.878 5.94407 320.304 5.94407 405.642 91.3499C426.959 112.667 443.015 137.332 453.674 163.706'
          stroke={color}
          strokeWidth='54.6597'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M472.669 56.0258L453.743 163.705L346.063 144.711'
          stroke={color}
          strokeWidth='54.6597'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M466.999 256.833C463.242 307.256 442.129 356.587 403.594 395.122C318.188 480.528 179.762 480.528 94.4249 395.122C73.1076 373.804 57.0513 349.139 46.3927 322.766'
          stroke={color}
          strokeWidth='54.6597'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.3302 430.446L46.3245 322.766L154.004 341.76'
          stroke={color}
          strokeWidth='54.6597'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_600_33929'>
          <rect width='500' height='486.472' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
