import type { IconProps } from './types';

export const IconLock = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_600_41312)'>
        <path
          d='M377.5 202.055V138.116C377.5 67.5 320.274 10.2739 249.657 10.2739C179.041 10.2739 121.815 67.5 121.815 138.116V202.055C101.712 202.055 85.2739 218.493 85.2739 238.596V453.219C85.2739 473.322 101.712 489.76 121.815 489.76H377.534C397.637 489.76 414.075 473.322 414.075 453.219V238.596C414.075 218.493 397.637 202.055 377.534 202.055H377.5ZM167.466 138.116C167.466 92.7397 204.281 55.9246 249.657 55.9246C295.034 55.9246 331.849 92.7397 331.849 138.116V202.055H167.466V138.116Z'
          stroke={color}
          strokeWidth='20.5479'
          strokeMiterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0_600_41312'>
          <rect width='349.315' height='500' fill={color} transform='translate(75)' />
        </clipPath>
      </defs>
    </svg>
  );
};
