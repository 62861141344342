import type { IconProps } from './types';

export const IconIntroduction1 = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_252_2399)'>
        <path
          d='M302.545 182.407V167.258C302.545 152.869 290.779 141.104 276.391 141.104H93.3825C78.9939 141.104 67.228 152.869 67.228 167.258V281.657C67.228 296.045 78.9939 307.811 93.3825 307.811H116.252V346.393C116.252 367.964 128.729 373.136 143.976 357.89L194.03 307.836H222.439'
          stroke={color}
          strokeWidth='14.7073'
          strokeMiterlimit='10'
        />
        <path
          d='M445.524 268.592C445.524 219.861 392.847 180.348 327.866 180.348C262.884 180.348 210.207 219.861 210.207 268.592C210.207 317.322 262.884 356.836 327.866 356.836C334.361 356.836 340.735 356.419 346.936 355.659L378.557 387.28C393.803 402.526 406.28 397.354 406.28 375.784V334.333C430.351 318.18 445.5 294.697 445.5 268.567L445.524 268.592Z'
          stroke={color}
          strokeWidth='14.7073'
          strokeMiterlimit='10'
        />
        <path
          d='M472.806 174.318L450.157 199.982'
          stroke={color}
          strokeWidth='14.7073'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M468.59 220.891L492.636 203.953'
          stroke={color}
          strokeWidth='14.7073'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.0034 103.355L46.5642 126.102'
          stroke={color}
          strokeWidth='14.7073'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.34375 130.22L32.1501 145.884'
          stroke={color}
          strokeWidth='14.7073'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_252_2399'>
          <rect
            width='500'
            height='307.408'
            fill='white'
            transform='translate(-0.00976562 96.0012)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
