import type { IconProps } from './types';

export const IconHorizontalAlignRight = ({
  width,
  height,
  color = 'white',
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.619 61H500V84.8095H47.619V61ZM238.095 120.524H500V144.333H238.095V120.524ZM95.2381 180.048H500V203.857H95.2381V180.048ZM0 239.571H500V263.381H0V239.571ZM238.095 299.095H500V322.905H238.095V299.095ZM47.619 358.619H500V382.429H47.619V358.619ZM166.667 418.143H500V441.952H166.667V418.143Z'
        fill={color}
      />
    </svg>
  );
};
