import type { IconProps } from './types';

export const IconImage = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_1893_37709)'>
        <path
          d='M67.01 54.7058C38.2355 54.7058 14.7061 78.2352 14.7061 107.01V394.608C14.7061 423.382 38.2355 446.912 67.01 446.912H433.039C461.814 446.912 485.343 423.382 485.343 394.608V107.01C485.343 78.2352 461.814 54.7058 433.039 54.7058H67.01Z'
          stroke={color}
          strokeWidth='29.4118'
          strokeMiterlimit='10'
        />
        <path
          d='M78.4312 298.039L178.48 197.99L353.774 373.333'
          stroke={color}
          strokeWidth='29.4118'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M297.99 317.549L361.569 253.971L421.569 313.971'
          stroke={color}
          strokeWidth='29.4118'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M289.216 196.863C310.874 196.863 328.431 179.305 328.431 157.647C328.431 135.989 310.874 118.431 289.216 118.431C267.557 118.431 250 135.989 250 157.647C250 179.305 267.557 196.863 289.216 196.863Z'
          stroke={color}
          strokeWidth='29.4118'
          strokeMiterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0_1893_37709'>
          <rect width='500' height='421.569' fill={color} transform='translate(0 40)' />
        </clipPath>
      </defs>
    </svg>
  );
};
