import type { IconProps } from './types';

export const IconPinch = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 502'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M19.8055 308.714L91.163 237.356'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.2564 258.165L19.8055 308.714'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M70.3447 300.263L19.8055 308.714'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M339.26 10.8055L271.091 78.9652'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M330.809 61.3447L339.26 10.8055'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M288.721 19.2564L339.26 10.8055'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M421.28 217.063C421.28 217.063 437.26 215.386 462.484 238.084C487.707 260.792 482.666 322.178 470.888 402.06C459.12 481.942 404.461 491.194 356.53 491.194C308.599 491.194 278.326 476.062 243.849 444.102C209.373 412.151 190.029 349.079 179.938 325.532C169.847 301.986 146.061 274.938 133.454 259.64C133.454 259.64 151.056 236.471 172.372 241.107C193.688 245.742 207.585 257.327 222.874 280.486C238.163 303.654 246.504 309.211 256.696 310.603C263.645 286.052 272.916 240.646 246.964 196.632C221.013 152.617 217.317 150.765 200.867 131.541C184.417 112.317 206.655 85.9046 227.971 101.194C249.287 116.482 293.762 154.47 297.006 157.714C300.25 160.958 302.102 166.515 303.494 171.15C304.885 175.786 328.975 231.375 328.975 231.375'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M375.294 226.758C372.05 214.713 370.658 171.629 309.972 179.039'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M366.492 192.005C380.39 192.005 419.769 186.66 427.648 245.392'
        stroke={color}
        strokeWidth='21.28'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
