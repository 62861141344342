import type { IconProps } from './types';

export const IconHorizontalAlignCenter = ({
  width,
  height,
  color = 'white',
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5610_229)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M23.8095 61H476.19V84.8095H23.8095V61ZM119.048 120.524H380.952V144.333H119.048V120.524ZM47.619 180.048H442.381V203.857H47.619V180.048ZM0 239.571H500V263.381H0V239.571ZM119.048 299.095H380.952V322.905H119.048V299.095ZM23.8095 358.619H476.19V382.429H23.8095V358.619ZM83.3333 418.143H416.667V441.952H83.3333V418.143Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_5610_229'>
          <rect width='500' height='380.952' fill='white' transform='translate(0 61)' />
        </clipPath>
      </defs>
    </svg>
  );
};
