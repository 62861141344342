import type { IconProps } from './types';

export const IconFontSize = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M452.478 412.5L448.131 385.12H446.826C425.087 403.815 399.435 418.152 370.75 418.152C329.446 418.152 300.326 391.207 300.326 349.033C300.326 297.739 343.794 271.228 443.348 260.359C442.913 232.533 432.913 208.196 397.696 208.196C372.489 208.196 349.011 219.489 328.587 232.533L309.891 198.63C334.663 182.978 368.141 167.326 406.391 167.326C465.946 167.326 493.337 205.152 493.337 268.62V412.5H452.478ZM443.348 292.087C372.489 300.783 348.576 318.609 348.576 345.565C348.576 369.033 364.663 378.598 385.968 378.598C406.826 378.598 423.783 368.598 443.348 350.772V292.087ZM196.163 321.217H84.8806L57.5001 412.5H6.1958L112.272 92.5543H170.522L276.587 412.5H223.12L196.163 321.217ZM171.391 238.62C160.522 203.848 150.957 168.196 140.957 132.12H139.218C130.087 168.63 120.098 203.848 109.663 238.62L96.6197 281.663H184L171.391 238.62Z'
        fill={color}
      />
    </svg>
  );
};
