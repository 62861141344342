import type { IconProps } from './types';

export const IconAlert = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_118_24218)'>
        <path
          d='M250 45.7954L14.7058 453.344H485.294L250 45.7954Z'
          stroke={color}
          strokeWidth='29.4118'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M250 187.952V325.893'
          stroke={color}
          strokeWidth='39.2157'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M250 384.031V384.717'
          stroke={color}
          strokeWidth='39.2157'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_118_24218'>
          <rect width='500' height='436.961' fill='white' transform='translate(0 31.0896)' />
        </clipPath>
      </defs>
    </svg>
  );
};
