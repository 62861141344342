import type { IconProps } from './types';

export const IconInfomationCircle = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_203_34)'>
        <path
          d='M50 34C53.3137 34 56 31.3137 56 28C56 24.6863 53.3137 22 50 22C46.6863 22 44 24.6863 44 28C44 31.3137 46.6863 34 50 34Z'
          fill={color}
        />
        <path
          d='M50 98C76.5097 98 98 76.5097 98 50C98 23.4903 76.5097 2 50 2C23.4903 2 2 23.4903 2 50C2 76.5097 23.4903 98 50 98Z'
          stroke={color}
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M50 46V74'
          stroke={color}
          strokeWidth='8'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_203_34'>
          <rect width='100' height='100' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
