import type { IconProps } from './types';

export const IconVerticalAlignBottom = ({
  width,
  height,
  color = 'white',
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M257.311 314.723L258.671 32.488L238.124 32L238.124 309.808L165.984 232.113L150 249.324L249.546 357L265.53 339.789L350 249.321L334.016 232.11L257.311 314.723Z'
        fill={color}
      />
      <rect x='500' y='432' width='500' height='50' transform='rotate(-180 500 432)' fill={color} />
    </svg>
  );
};
