import type { IconProps } from './types';

export const IconCamera = ({ width, height, color = 'black', className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 14'
      width={width}
      height={height}
      className={className}
    >
      <path
        d='M9.3,1.3h-2.6c-1,0-1.2.7-1.5,1.4-.3.7-.6.6-.6.6h-1.9c-.8,0-1.5.6-1.5,1.4v6.6c0,.8.7,1.4,1.5,1.4h10.5c.8,0,1.5-.6,1.5-1.4v-6.6c0-.8-.7-1.4-1.5-1.4h-2s-.3.1-.6-.6c-.3-.7-.6-1.4-1.5-1.4'
        fill='none'
        stroke={color}
        strokeMiterlimit='10'
        strokeWidth='1.1'
      />
      <path
        d='M8,11c1.9,0,3.4-1.4,3.4-3.1s-1.5-3.1-3.4-3.1-3.4,1.4-3.4,3.1,1.5,3.1,3.4,3.1Z'
        fill='none'
        stroke={color}
        strokeMiterlimit='10'
        strokeWidth='1.1'
      />
    </svg>
  );
};
