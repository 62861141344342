import type { IconProps } from './types';

export const IconHearts2 = ({
  width,
  height,
  color = 'white',
  className,
  fillColor = 'none',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.9998 25.9987C20.3546 22.9739 23.1194 20.7365 25.2268 17.2591C27.3343 13.7818 27.7717 9.2495 25.3943 6.85211C23.0169 4.45722 18.9971 4.34723 16.6197 6.74212C15.4298 7.93956 14.9998 8.68453 14.9998 8.68453C14.9998 8.68453 14.5698 7.93956 13.3799 6.74212C11.0025 4.34723 6.98517 4.45722 4.60778 6.85211C2.22789 9.247 2.66537 13.7793 4.77277 17.2591C6.88018 20.7365 9.64505 22.9764 14.9998 25.9987Z'
        stroke={color}
        strokeWidth='1.76471'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={fillColor}
      />
    </svg>
  );
};
