import type { IconProps } from './types';

export const IconVerticalAlignTop = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M242.689 169.277L241.329 451.512L261.876 452L261.876 174.192L334.016 251.887L350 234.676L250.454 127L234.47 144.211L150 234.679L165.984 251.89L242.689 169.277Z'
        fill={color}
      />
      <rect y='52' width='500' height='50' fill={color} />
    </svg>
  );
};
