import type { IconProps } from './types';

export const IconVerticalAlignMiddle = ({
  width,
  height,
  color = 'white',
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 500 501'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5610_363)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M242.689 343.277L241.329 500.512L261.876 501L261.876 348.192L334.016 425.887L350 408.675L250.454 301L234.47 318.211L150 408.679L165.984 425.89L242.689 343.277Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M257.311 158.723L258.671 1.48804L238.124 0.999995L238.124 153.808L165.984 76.1129L150 93.3244L249.546 201L265.53 183.789L350 93.3211L334.016 76.1096L257.311 158.723Z'
          fill={color}
        />
        <rect y='226' width='500' height='50' fill={color} />
      </g>
      <defs>
        <clipPath id='clip0_5610_363'>
          <rect width='500' height='500' fill={color} transform='translate(0 0.666626)' />
        </clipPath>
      </defs>
    </svg>
  );
};
