import type { IconProps } from './types';

export const IconCheck = ({ width, height, color = 'white', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 500 500'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M500 111.9L186.347 425.554L0 205.996L36.9004 185.701L186.347 335.148L463.1 75L500 111.9Z'
        fill={color}
      />
    </svg>
  );
};
